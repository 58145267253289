<template>
    <v-row class="p-3">

        <v-col cols="auto">
            <v-dialog transition="dialog-top-transition" max-width="600" v-model="sdialog">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" v-bind="attrs" v-on="on"><v-icon class="mr-2" large>mdi-account</v-icon>
                        Select Customer</v-btn>
                </template>
                <template v-slot:default="dialog">
                    <v-card>
                        <v-toolbar color="primary" dark dense>Select Customer</v-toolbar>
                        <v-card>
                            <v-card-title>
                                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                                    hide-details></v-text-field>
                            </v-card-title>
                            <v-data-table :headers="headers" :items="customerList" :search="search" :itemsPerPage="5">
                                <template v-slot:item.select="{ item }">
                                    <v-btn dense @click="selectCustomer(item.customerNumber, item.customerName)">Select</v-btn>
                                </template>
                            </v-data-table>
                        </v-card>
                        <v-card-actions class="justify-end">
                            <v-btn text @click="sdialog = false">Close</v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-dialog>
        </v-col>
    </v-row>
</template>
<script>

export default {
    emits: ['customer-selected'],
    data() {
        return {
            sdialog: false,
            search: '',
            headers: [
                {
                    text: 'Customer Number',
                    align: 'start',
                    value: 'customerNumber',
                },
                { text: 'Customer Name', value: 'customerName' },
                { text: 'select', value: 'select' },
            ],
            customerList: [],
        }
    },
    methods: {
        selectCustomer(customerNumber, customerName){
            this.$emit('customer-selected', { customerNumber, customerName });
            this.sdialog = false;
        }
    },
    mounted() {
        this.$axios({
            method: 'get',
            url: `${this.$baseURL}/PositionMapping/customersList`,
        })
            .then((response) => {
                this.customerList = response.data.customersList;

            });

        // this.getPositions(1)
    }

}
</script>