<template>
    <div>
        <select-customer v-on:customer-selected="customerSelected" v-if="!isCustomerUser"></select-customer>

        <!-- <v-form ref="form" v-model="valid" lazy-validation v-if="!isCustomerUser">
              <v-container>
                    <v-row>
                        <v-col cols="12" sm="3" md="3">
                            <v-menu
                                :close-on-content-click="false"
                                :nudge-width="200"
                                offset-x>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                        color="green"
                                        dark
                                        v-bind="attrs"
                                        class="mt-2 ml-2"
                                        v-on="on">
                                        <v-icon  class="mr-2" large>mdi-account</v-icon>
                                        {{selectedCustomer.text}}
                                        </v-btn>
                                    </template>
                                    <v-card
                                    class="mx-auto"
                                    max-width="500">
                                        <v-toolbar
                                        flat
                                        color="transparent">
                                            <v-toolbar-title>Select Customer</v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                            icon
                                            @click="$refs.search.focus()">
                                                <v-icon>mdi-magnify</v-icon>
                                            </v-btn>
                                        </v-toolbar>
                                         <v-container class="py-0">
                                            <v-row
                                            align="center"
                                            justify="start">
                                                <v-col
                                                v-for="(selection, i) in selections"
                                                :key="selection.text"
                                                class="shrink">
                                                    <v-chip
                                                        :disabled="loading"
                                                        close
                                                        @click:close="selected.splice(i, 1)">
                                                        <v-icon
                                                        left
                                                        v-text="selection.icon"
                                                        ></v-icon>
                                                        {{ selection.text }}
                                                    </v-chip>
                                                </v-col>
                                                <v-col
                                                v-if="!allSelected"
                                                cols="12"
                                                >
                                                <v-text-field
                                                    ref="search"
                                                    v-model="searchItem"
                                                    full-width
                                                    hide-details
                                                    label="Search"
                                                    single-line
                                                ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                        <v-list>
                                            <template v-for="item in categories">
                                                <v-list-item
                                                :key="item.customerNumber"
                                                :disabled="loading"
                                                v-if="!selected.includes(item)"
                                                @click="SetselectedCustomer(item)">
                                                <v-list-item-avatar>
                                                    <v-icon
                                                    :disabled="loading"
                                                    v-text="item.icon"></v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-title v-text="item.text"></v-list-item-title>
                                                </v-list-item>
                                            </template>
                                        </v-list>
                                    </v-card>
                                </v-menu>
                        </v-col>
                        <v-col cols="12" sm="2" md="2">
                            <v-btn
                            class="ma-2"
                            outlined
                            small
                            fab
                            color="green">
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
              </v-container>
         </v-form> -->
        <h3 v-else>
            {{ this.$store.getters.customer }}
        </h3>



        <v-container id="user-profile" fluid tag="section" class="grey lighten-5 mb-1 mt-0">
            <v-card class="px-5 py-10" icon="clipboard-text" title="Jobs list">
                <v-form ref="jobsForm" v-model="jobsFormModel" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="5" sm="6" md="6">
                                <v-card class="mx-auto">
                                    <v-toolbar color="green" dense>
                                        <v-toolbar-title>All Jobs</v-toolbar-title>
                                        <v-text-field v-model="searchToken" flat label="Search" class="mx-4 mt-7"
                                            solo-inverted prepend-icon="mdi-magnify" clearable @click:clear="clearSearch"
                                            single-line></v-text-field>
                                        <v-spacer></v-spacer>
                                    </v-toolbar>
                                    <v-progress-linear :active="loading" :indeterminate="loading" absolute bottom
                                        color="green accent-4"></v-progress-linear>
                                    <v-data-table :headers="JobFields" :items="items" ref="dtPositions"
                                        :search="searchToken" class="elevation-1">
                                        <template v-slot:[`item.Actions`]="{ item }">
                                            <v-row class="my-border">
                                                <v-col cols="6" v-if="item.summary">
                                                    <v-btn x-small color="green"
                                                        @click="ViewJob(item)"><v-icon>mdi-view-list</v-icon> </v-btn>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-btn x-small color="green" @click="AddJob(item)">
                                                        <v-icon>mdi-plus</v-icon> </v-btn>
                                                </v-col>
                                            </v-row>
                                        </template>
                                    </v-data-table>
                                </v-card>
                            </v-col>
                            <v-col cols="1" sm="2" md="1">
                            </v-col>
                            <v-col cols="5" sm="5" md="5">
                                <v-card>
                                    <v-toolbar color="green" dense>
                                        <v-toolbar-title>User Jobs</v-toolbar-title>
                                        <v-text-field v-model="UsersJobSearch" flat label="Search" class="mx-4 mt-7"
                                            solo-inverted prepend-icon="mdi-magnify" clearable
                                            @click:clear="clearUsersJobSearch" single-line></v-text-field>
                                        <v-spacer></v-spacer>
                                    </v-toolbar>
                                    <v-data-table :headers="UserJobFields" :items="userJobs" ref="dtPositions"
                                        :search="UsersJobSearch" class="elevation-1">
                                        <template v-slot:[`item.Actions`]="{ item }">
                                            <v-row class="my-border">
                                                <v-col cols="2" sm="2" md="2">
                                                    <v-btn x-small color="green" @click="RemoveJob(item)"> Remove </v-btn>
                                                </v-col>
                                            </v-row>
                                        </template>
                                    </v-data-table>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="5" sm="5" md="5">
                                <!-- <v-row>
                                          <v-col cols="12" sm="12" md="12">
                                         <v-pagination

                                            v-model="page"
                                            :length="Math.ceil(filteredItems/perPage)">
                                        </v-pagination>
                                          </v-col>
                                        </v-row> -->
                                <!-- <v-row>
                                            <v-col cols="10">
                                            </v-col>
                                            <v-col cols="2" sm="2" md="2">
                                                <v-btn color="green darken-1" @click="AddJob" :disabled="selectedFromJobsList==null" center>Add</v-btn>
                                            </v-col>
                                        </v-row> -->
                            </v-col>
                            <v-col cols="1" sm="2" md="1">

                            </v-col>
                            <v-col cols="5" sm="5" md="5">
                                <!-- <v-row>
                                          <v-col cols="12" sm="12" md="12">
                                         <v-pagination

                                            v-model="page"
                                            :length="Math.ceil(filteredItems/perPage)">
                                        </v-pagination>
                                          </v-col>
                                        </v-row> -->
                                <v-row>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-btn color="green darken-1" @click="RemoveJob"
                                            :disabled="selectedFromUserJobsList == null">Remove</v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card>
        </v-container>

        <v-dialog v-model="approveReqDialog" max-width="500px">
            <v-card>
                <v-progress-linear :active="RemoveLoading" :indeterminate="RemoveLoading" absolute bottom
                    color="green accent-4">
                </v-progress-linear>
                <v-card-title class="text-h3">Are you sure ? </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="ConfirmReomveJob">Yes</v-btn>
                    <v-btn color="green darken-1" text @click="closeApprove">No</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="loadingCircular" persistent fullscreen content-class="loading-dialog">
            <v-container fill-height>
                <v-layout row justify-center align-center>
                    <v-progress-circular :indeterminate='loadingCircular' :size="70" :width="7" color="green">
                    </v-progress-circular>
                </v-layout>
            </v-container>
        </v-dialog>

        <v-dialog v-model="summaryDialog" max-width="500px">
            <v-card>

                <v-card-title class="text-h5">Job details </v-card-title>
                <v-card-text v-if="selectedJobDetails">
                    <v-row align="center">
                        <v-col class="text-h6" cols="4">
                            Job Family
                        </v-col>
                        <v-col cols="8">
                            {{ selectedJobDetails.jobFamilyName }}
                        </v-col>
                        <v-col class="text-h6" cols="4">
                            Job Name
                        </v-col>
                        <v-col cols="8">
                            {{ selectedJobDetails.englishDescription }}
                        </v-col>
                        <v-col class="text-h6" cols="4">
                            Description
                        </v-col>
                        <v-col cols="8">
                            {{ selectedJobDetails.summary }}
                        </v-col>
                        <v-col class="text-h6" cols="4">
                            Responsibilities
                        </v-col>
                        <v-col cols="8">
                            {{ selectedJobDetails.responsibilities }}
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="summaryDialog = false">close</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <!-- <div class="text-center">
                <v-snackbar v-model="snackbar" timeout="3000">
                    {{ alertMsg }}
                    <template v-slot:action="{ attrs }">
                        <v-btn
                            color="green"
                            text
                            v-bind="attrs"
                            @click="snackbar = false">
                            Close
                        </v-btn>
                    </template>
            </v-snackbar>
        </div> -->

    </div>
</template>

<script>

import SelectCustomer from './SelectCustomer';

export default {
    components: {
        SelectCustomer
    },
    computed: {
        filteredItems() {
            return this.items.filter(
                item => {
                    if (!this.search) return this.items;
                    return (item.englishDescription.toLowerCase().includes(this.search))
                }
            ).sort((a, b) => a.englishDescription < b.englishDescription ? -1 : 1);

        },
        categories() {
            const search = this.searchItem.toLowerCase()

            if (!search) return this.newCustomerList

            return this.newCustomerList.filter(item => {
                const text = item.text.toLowerCase()

                return text.indexOf(search) > -1
            })
        },

        isCustomerUser() {
            return this.$store.getters.customer != 'No Customer';
        },
        filteredUserJobs() {
            return this.userJobs.filter(
                item => {
                    if (!this.UsersJobSearch) return this.userJobs;
                    return (item.englishDescription.toLowerCase().includes(this.UsersJobSearch))
                }
            ).sort((a, b) => a.englishDescription < b.englishDescription ? -1 : 1);

        },
        customerNumber() {
            if (this.$store.state.userDetails.subscriptionId != -1) {
                return this.$store.state.userDetails.subscriptionId;
            }
            else if (this.selectedCustomer) {
                return this.selectedCustomer.customerNumber
            }
            else
                return -1;

        },
        visiblePages() {
            return this.filteredItems.slice((this.page - 1) * this.perPage, this.page * this.perPage)
        },
        allSelected() {
            return this.selected.length === this.newCustomerList.length
        },
        selections() {
            const selections = []

            for (const selection of this.selected) {
                selections.push(selection)
            }

            return selections
        },
    },
    watch: {
        selected() {
            this.searchItem = ''
        },
    },
    data() {
        return {
            selectedFromJobsList: null,
            valid: true,
            selectedFromUserJobsList: null,
            searchItem: '',
            UsersJobSearch: '',
            page: 1,
            perPage: 6,
            searchToken: '',
            currentPage: 1,
            totalItems: 1,
            items: [],
            newCustomerList: [],
            selected: [],
            userJobs: [],
            loading: false,
            jobsFormModel: null,
            approveReqDialog: null,
            summaryDialog: null,
            loadingCircular: false,
            selectedJob: null,
            selectedJobDetails: null,
            selectedCustomer: {
                text: 'Select Customer',
                customerNumber: -1
            },
            search: {
                customer: null,
                surveyYear: null,
                selectedPositions: null,
                positionIncluded: 1
            },
            RemoveLoading: false,
            JobFields: [
                { value: 'jobFamilyName', text: 'Job Family', width: '240' },
                { value: 'englishDescription', text: 'Job Name', width: '200' },
                { value: 'Actions', text: 'Actions', width: '140' },
            ],
            UserJobFields: [
                { value: 'Actions', text: 'Actions', width: '100' },
                { value: 'englishDescription', text: 'Job Name', width: '200' },
            ],
        }
    },
    methods: {
        AddJob(item) {
            console.log('add' + this.customerNumber);
            var selectedItem = item
            this.loading = true;
            var index = this.items.indexOf(selectedItem)
            this.$axios({
                method: 'post',
                url: `${this.$baseURL}/JobTitle/userJob`,
                data: {
                    CustomerNumber: this.selectedCustomer.customerNumber,
                    JobId: selectedItem.id,
                }
            }).then((response) => {
                if (response.data.status == true) {
                    this.userJobs.push(selectedItem)
                    this.userJobs.sort((a, b) => a.englishDescription < b.englishDescription ? -1 : 1);
                    this.items.splice(index, 1);
                    this.loading = false;
                }
            });


        },
        ViewJob(item) {
            this.selectedJobDetails = item;
            this.summaryDialog = true;
        },
        customerSelected(cn) {
            this.selectedCustomer = {
                text: cn.customerName,
                customerNumber: cn.customerNumber
            };
            this.LoadData(cn.customerNumber);
        },
        RemoveJob(item) {
            this.selectedJob = item;
            this.approveReqDialog = true;
        },
        ConfirmReomveJob() {
            this.RemoveLoading = true;
            this.$axios({
                method: 'delete',
                url: `${this.$baseURL}/JobTitle/userJob`,
                data: {
                    CustomerNumber: this.selectedCustomer.customerNumber,
                    JobId: this.selectedJob.id,
                }
            }).then((response) => {
                if (response.data.status == true) {
                    console.log('this.selectedJob')
                    console.log(this.selectedJob)
                    this.items.push(this.selectedJob)
                    // this.items.sort( (a,b)=> a.englishDescription < b.englishDescription ? -1:1 )

                    let index = this.userJobs.indexOf(this.selectedJob, 0);
                    this.userJobs.splice(index, 1);


                    this.RemoveLoading = false;
                    this.approveReqDialog = false;
                    this.search = '';
                    this.UsersJobSearch = '';
                }
            });


        },
        closeApprove() {
            this.approveReqDialog = false;
        },
        clearSearch() {
            this.search = '';
        },
        clearUsersJobSearch() {
            this.UsersJobSearch = '';
        },
        SetselectedCustomer(item) {
            this.selectedCustomer = item;
            this.LoadData(item.customerNumber)
        },
        LoadData(cn) {
            let selectedCustomerNumber = null;
            if (cn) {
                selectedCustomerNumber = cn;
            }
            else {
                selectedCustomerNumber = this.customerNumber;
            }

            console.log('load' + this.selectedCustomer);

            //Load all jobs
            this.loading = true;



            this.$axios({
                method: 'get',
                url: `${this.$baseURL}/JobTitle/lookup`,
            })
                .then((response) => {
                    console.log('response.data.result.............')
                    console.log(response.data.result)
                    this.items = response.data.result;
                    this.loading = false;

                    this.$axios({
                        method: 'get',
                        url: `${this.$baseURL}/JobTitle/userJob`,
                        params: {
                            CustomerNumber: selectedCustomerNumber,
                        }
                    })
                        .then((_response) => {

                            this.userJobs = response.data.result.filter(item => {
                                if (_response.data.result.some((o) => o.jobId === item.id)) {
                                    return true;
                                }
                                return false;

                            })
                            this.userJobs.forEach((item) => {
                                let index = this.items.indexOf(item, 0);
                                this.items.splice(index, 1);
                            });


                            this.loading = false;
                        });


                    // this.userJobs.filter(
                    //     item => {
                    //         if(!this.UsersJobSearch) return this.userJobs;
                    //         return(item.englishDescription.toLowerCase().includes(this.UsersJobSearch))
                    //     }
                    // ).sort( (a,b)=> a.englishDescription < b.englishDescription ? -1:1 );


                });





        },
    },
    mounted() {
        if (this.customerNumber == -1) { //then load cutomers
            this.$axios({
                method: 'get',
                url: `${this.$baseURL}/PositionMapping/Searchlookups`,
            })
                .then((response) => {
                    // console.log(response.data.customersList);
                    this.customerList = response.data.customersList;
                    this.newCustomerList = response.data.customersList
                        .map(customer => ({
                            text: customer.customerName,
                            icon: 'mdi-map-marker',
                            customerNumber: customer.customerNumber
                        }))
                    this.surveyYears = response.data.surveyYears;
                    this.search.surveyYear = this.surveyYear;
                });

            // this.getPositions(1)
        }
        else {
            this.selectedCustomer.customerNumber = this.customerNumber;
            this.LoadData();

        }


    }

}
</script>

<style scoped>
v-progress-circular {
    margin: 1rem;
}

.v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
.v-data-table>.v-data-table__wrapper>table>thead>tr>td,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>td {
    font-size: 1rem;
    height: 35px;
    vertical-align: middle;
}
</style>